import { useState, useEffect } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Grid, Button } from '@mui/material';

import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import { listSFBacklogs } from "../../../graphql/queries";
import { groupBy, orderBy, uniq, mapValues } from 'lodash';
import { Avatar } from '@mui/material';

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      marginBottom: '10px',
    }}
    spacing={2}
  >
    <GridToolbarExport />
    <GridToolbarQuickFilter
      sx={{
        position: 'absolute',
        padding: '18px',
        right: '15px'
      }}
    />
  </GridToolbarContainer>
);

const Summary = ({ loading }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(loading);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listSFBacklogs, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listSFBacklogs.items;
        items.push(...fetchedItems);
        setData(items);

        nextToken = response.data.listSFBacklogs.nextToken;
      } while (nextToken);

      setIsLoading(false);
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      return [];
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = async () => {
    try {
      await fetchData();
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  const columns = [
    { field: 'node', headerName: 'Node', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', width: 300, align: 'center', headerAlign: 'center' },
    { field: 'ops_owner', headerName: 'Ops Owner', width: 150, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;

        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
        return null; 
      }
    },
    { field: 'backup_owner', headerName: 'Backup', width: 150, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;

        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
        return null; 
      }
    },
    { field: 'capacity', headerName: 'Capacity', type: 'number', width: 150, align: 'center', headerAlign: 'center', sortComparator: (v1, v2) => v2 - v1 },
  ];

  const uniqueData = data.reduce((acc, item) => {
    const existingItem = acc.find(i => i.node === item.node);
    if (existingItem) {
      existingItem[item.cpt_date] = item.pending_shipments;
    } else {
      const newItem = { id: `${item.node}-${item.cpt_date}`, node: item.node, capacity: item.capacity, ops_owner: item.ops_owner, backup_owner: item.backup_owner, seller_name: item.seller_name };
      newItem[item.cpt_date] = item.pending_shipments;
      acc.push(newItem);
    }
    return acc;
  }, []);

  const columnWithCptDate = [
    ...columns,
    
    {
      field: 'totalPendingShipments',
      headerName: 'Total Backlog',
      type: 'number',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        const row = params.row;
        return Object.values(row).reduce((total, value) => {
          if (typeof value === 'number' && value !== row.capacity) {
            return total + value;
          }
          return total;
        }, 0);
      }
    },
    {
      field: 'pendingShipmentsPerCapacity',
      headerName: 'Backlog days',
      type: 'number',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        const row = params.row;
        const sumValues = Object.values(row).reduce((total, value) => {
          if (typeof value === 'number' && value !== row.capacity) {
            return total + value;
          }
          return total;
        }, 0);
        return row.capacity > 0 ? (sumValues / row.capacity): 0;
      },
      valueFormatter: (params) => {
        return `${params.value.toFixed(2)}`;
      },
      sortComparator: (v1, v2) => v2 - v1
    }
    ,...orderBy(uniq(data.map(item => item.cpt_date)), [], 'asc').map(date => ({
      field: date,
      headerName: date,
      type: 'number',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2) => v2 - v1,
      valueGetter: (params) => {
        const node = params.row.node;
        const item = uniqueData.find(i => i.node === node);
        return item ? item[date] : 0;
      }
    })),
  ];


  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6}>
           <Button
                variant="contained"
                color="primary"
                onClick={handleRefresh}
              >
                Refresh
              </Button>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Typography
                sx={{
                  marginTop: '2vh',
                  marginLeft: 3,
                  fontFamily: 'Source Sans Pro',
                  fontSize: 'calc(15px + 0.5vh)',
                  fontWeight: 'bold',
                }}
              >
                PENDING SHIPMENTS (15 DAYS)
              </Typography>
            </Grid>
          </Grid>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              minHeight: '30vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={uniqueData}
            columns={columnWithCptDate}
            components={{
              Toolbar: CustomToolbar,
            }}
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: { sortModel: [{ field: 'pendingShipmentsPerCapacity', sort: 'asc' }] },
            }}
          />
        </Grid>
      )}
    </div>
  );
};

export default withAuthenticator(Summary);