import { useState, useEffect, useMemo } from "react";
import { listSummaryDropOffs} from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid';
import { Typography, Button, Grid } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';



Amplify.configure(awsconfig);

const groupTypes = ['day','week'];

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);
  const [defectData, setDefectsData] = useState([]); // Estado para armazenar dados de defects

  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      
      // Calculate the date 30 days ago
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listSummaryDropOffs, {
            limit: 20000,
            nextToken,

          })
        );
        const fetchedItems = response.data.listSummaryDropOffs.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSummaryDropOffs.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    try {
      fetchData();
      console.log("teste",data)
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);




  

  


  
  const groupData = useMemo(() => {
    // Ordena os dados por 'expected_ship_date' antes de agrupá-los
    const sortedData = [...data].sort((b, a) => new Date(b.miss_week) - new Date(a.miss_week));

    return groupBy(sortedData,(item) => {
      const date_value = new Date(item.miss_week);
          const weekNumber = date_value;
          return item.miss_week;
        
    });
  }, [data]);

  const aggregatedData = useMemo(() => {
    const uniqueData = {};
    let id = 0;
  
    for (const key in groupData) {
      if (groupData.hasOwnProperty(key)) {
        const items = groupData[key];
        uniqueData[key] = {
          id: id++,
          miss_week: key,
          order_count_method: 0,
          general_lsr: 0,
          mexsd: 0,
          late_drop: 0,
          mexsd_re: 0,
          late_drop_re: 0,
          lsr_re_f: 0, // Add this new field
          cr: 0,
          cr_not_scheduled:0,
          order_count_evaluation: 0,



          general_lsr_perc: 0,
          mexsd_perc: 0,
          late_drop_perc: 0,
          mexsd_re_perc: 0,
          late_drop_re_perc: 0,
          lsr_re_f_perc: 0, // Add this new field
          cr_perc: 0,
          cr_n_scheduled_perc: 0,

        };
  
        for (const item of items) {
          uniqueData[key].order_count_method += item.order_count_method;
          uniqueData[key].order_count_evaluation += item.order_count_evaluation;
          uniqueData[key].general_lsr += item.general_lsr_f || 0;
          uniqueData[key].mexsd += item.mexsd_f || 0;
          uniqueData[key].late_drop += item.late_drop || 0;
          uniqueData[key].mexsd_re += item.mexsd_re_f || 0;
          uniqueData[key].late_drop_re += item.late_drop_re || 0;
          uniqueData[key].cr += item.cr || 0;
          uniqueData[key].lsr_re_f += item.lsr_re_f || 0;
          uniqueData[key].cr_not_scheduled += item.cr_not_scheduled || 0;

  
          // Check if the amazon_comments is "Incorrect Address" and add the value to the incorrect_address_sum
          
           
        }

        
  
        if (uniqueData[key].order_count_method && uniqueData[key].general_lsr) {
          uniqueData[key].general_lsr_perc = ((uniqueData[key].general_lsr) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].mexsd) {
          uniqueData[key].mexsd_perc = ((uniqueData[key].mexsd) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].late_drop) {
          uniqueData[key].late_drop_perc = ((uniqueData[key].late_drop) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].mexsd_re) {
          uniqueData[key].mexsd_re_perc = ((uniqueData[key].mexsd_re) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].late_drop_re) {
          uniqueData[key].late_drop_re_perc = ((uniqueData[key].late_drop_re) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].lsr_re_f) {
          uniqueData[key].lsr_re_f_perc = ((uniqueData[key].lsr_re_f) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_evaluation && uniqueData[key].cr) {
          uniqueData[key].cr_perc = ((uniqueData[key].cr) / uniqueData[key].order_count_evaluation);
        }

        if (uniqueData[key].order_count_evaluation && uniqueData[key].cr_not_scheduled) {
          uniqueData[key].cr_n_scheduled_perc = ((uniqueData[key].cr_not_scheduled) / uniqueData[key].order_count_evaluation);
        }
      }
    }
  
    return Object.values(uniqueData);
  }, [data]);

  console.log('aggregatedData:', aggregatedData);

  const sortedData1 = useMemo(() => {
    const sortedItems = [...aggregatedData].sort((a, b) => {
      const weekComparison = b.miss_week - a.miss_week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [aggregatedData]);




  const MissColumns = Array.from(new Set([
    { field: 'miss_week', headerName: 'Week', width:50 },
    { field: 'order_count_method', type: 'number' , headerName: 'Order Count', width:100, align: 'center', headerAlign: 'center'},
    { field: 'general_lsr', type: 'number' , headerName: 'LDR', width:100, align: 'center', headerAlign: 'center'},
    { field: 'general_lsr_perc', type: 'number' , headerName: 'LDR %', width:100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 9.25;
        
        return (
          <div
  

          >
            {formattedValue}
          </div>
        );
      },
    },
    { field: 'lsr_re_f', type: 'number' , headerName: 'LDR RE', width:100, align: 'center', headerAlign: 'center'},
    { field: 'lsr_re_f_perc', type: 'number' , headerName: 'LDR RE %', width:100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 10.55;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    { field: 'mexsd', type: 'number' , headerName: 'MEXSD', width:100, align: 'center', headerAlign: 'center', },
    { field: 'mexsd_perc', type: 'number' , headerName: 'MEXSD %', width:100, align: 'center', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 9.3;
        
        return (
          <div
            
          >
            {formattedValue}
          </div>
        );
      },
    },
    { field: 'mexsd_re', type: 'number' , headerName: 'MEXSD RE', width:100, align: 'center', headerAlign: 'center', },
    { field: 'mexsd_re_perc', type: 'number' , headerName: 'MEXSD RE %', width:120, align: 'center', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 3.15;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    { field: 'late_drop', type: 'number' , headerName: 'LH', width:100, align: 'center', headerAlign: 'center', },
    { field: 'late_drop_perc', type: 'number' , headerName: 'LH %', width:100, align: 'center', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 93;
        
        return (
          <div

          >
            {formattedValue}
          </div>
        );
      },
    },
    
    { field: 'late_drop_re', type: 'number' , headerName: 'LH RE', width:100, align: 'center', headerAlign: 'center', },
    { field: 'late_drop_re_perc', type: 'number' , headerName: 'LH RE %', width:100, align: 'center', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 7.40;
       
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    { field: 'cr', type: 'number' , headerName: 'CR', width:100, align: 'center', headerAlign: 'center', },
    { field: 'cr_perc', type: 'number' , headerName: 'CR %', width:100, align: 'center', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 3.35;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    { field: 'cr_not_scheduled', type: 'number' , headerName: 'CR Not Scheduled', width:150, align: 'center', headerAlign: 'center', },
    { field: 'cr_n_scheduled_perc', type: 'number' , headerName: 'CR Not Scheduled %', width:150, align: 'center', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 3.35;
        
        return (
          <div
            
            
          >
            {formattedValue}
          </div>
        );
      },
    },
    




    
  ]));




  
  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '400px'
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <div>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', justifyContent: 'center' }}>
      </div>
      <Grid container spacing={12} direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Summary
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              minHeight: '30vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedData1 || []}
            columns={MissColumns}
            disableColumnSelector
            disableDensitySelector
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
          />
        </Grid>
        
      </Grid>
    </div>
  );
  };
  
  export default withAuthenticator(Summary);